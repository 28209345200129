.container {
    padding: 1.5rem;
    display: flex;
    font-family: Gilroy;
  }
  .logo {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex: 1;
  }
  
  .logo > img {
    width: 2.5rem;
    height: 2.5rem;
  }
  .logo > span {
    font-weight: 600;
    font-size: 40px;
  }
  
  .right {
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: center;
  }
  .menu {
    list-style: none;
    display: flex;
    gap: 2rem;
    font-weight: 500;
  }
  .menu > li:hover {
    color: #fe956f;
    cursor: pointer;
  }
  
  .search {
    width: 7rem;
    outline: none;
    border: none;
    border-radius: 5px;
    padding: 0.5rem;
    height: 30%;
  }
  .cart {
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .bars{
    display: none;
  }
  
  @media screen and (max-width: 856px) {
    .search {
      display: none;
    }
    .right {
      gap: 1rem;
    }
  }
  
  @media screen and (max-width: 640px) {
    .right {
      position: absolute;
      right: 2rem;
      z-index: 9999;
      background:white;
      color: var(--black);
      border-radius: 5px;
      flex-direction: column;
      padding: 1rem;
  }
    .menu{
      flex-direction: column;
      margin-left: -2rem;
      display: none;
    }
    .bars{
      display: block;
    }
  }
  